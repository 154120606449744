<template>
    <v-app>
        <div class="printContacts" id="printContacts">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        dark
                        class="buttons--add order__btn no-print"
                        style="margin-right: 10px, z-index: 5"
                        fab
                        small
                        @click="print()"
                    >
                        <v-icon size="18" dark>mdi-printer</v-icon>
                    </v-btn>
                </template>
                <span>Drukuj logi</span>
            </v-tooltip>
            <!-- {{finishArr}} -->
            <table border="1" style="width: 100%; font-size: 12px">
                <!-- v-for="arr in category.arr" :key="arr.id" -->

                <tr style="font-size: 12px;  padding: 5px">
                    <th style="font-size: 12px;  padding: 5px">Stanowisko/sekcja</th>
                    <th style="font-size: 12px;  padding: 5px">Komórka/oddział/ inaczej Departament</th>
                    <th style="font-size: 12px; padding: 5px">
                        {{$moment(new Date())
                        .subtract(3, 'months')
                        .endOf('month')
                        .format('YYYY-MM')}}
                    </th>
                    <th style="font-size: 12px; padding: 5px">
                        {{$moment(new Date())
                        .subtract(2, 'months')
                        .endOf('month')
                        .format('YYYY-MM')}}
                    </th>
                    <th style="font-size: 12px; padding: 5px">
                        {{$moment(new Date())
                        .subtract(1, 'months')
                        .endOf('month')
                        .format('YYYY-MM')}}
                    </th>
                    <th style="font-size: 12px;  padding: 5px">Logowania za ostatnie 3 miesiące</th>
                </tr>

                <tbody v-for="roles in finishArr" :key="roles.id">
                    <tr :style="`background-color: ${roles.color}`">
                        <td>{{roles.finishName}}</td>
                        <td>{{roles.department}}</td>
                        <td>{{roles.logs[0].length}}</td>
                        <td>{{roles.logs[1].length}}</td>
                        <td>{{roles.logs[2].length}}</td>
                        <td>{{roles.logs[0].length + roles.logs[1].length + roles.logs[2].length}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <Preloader /> -->
    </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import Snackbars from "@/components/Global/Snackbars/Snackbars.vue";
// import Preloader from "@/components/Global/Preloader/Preloader.vue";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import moment from "moment";

export default {
    data: () => ({
        allCategory: [],
        pages: 0,
        preloader: false,
        ordynator: [],
        mnger: [],
        finishArr: [],
        emptyCategory: [],
    }),
    computed: {
        ...mapGetters(["getThreeMonthLogs", "getDepartmentsItems"]),
    },

    // components: { Preloader },

    methods: {
        logs() {
            const roles = [
                {
                    name: "Ordynator",
                    secondName: "Z-ca Ordynatora",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Ordynator / z-ca Ordynatora",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Kierownik",
                    secondName: "Z-ca Kierownika",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Kierownik / z-ca Kierownika",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Lekarz",
                    secondName: "Rezydent",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Lekarze / rezydenci",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Pielęgniarka koordynująca",
                    secondName: "Pielęgniarka oddziałowa",
                    thirdName: "Z-ca pielęgniarki oddziałowej",
                    fourthName: "",
                    fiveName: "",
                    finishName:
                        "Pielęgniarka koordynująca / oddziałowa / z-ca pielęgniarki oddziałowej",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Pielęgniarka",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Pielęgniarki",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Sekretarka",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Sekretarki",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Rejestratorka",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Rejestratorki",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Psycholog",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Psycholodzy",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Farmaceuta",
                    secondName: "Technik farmacji",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Farmaceuta, technik farmacji",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Pracownik",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Pracownik",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Technik",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Technik",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Technik RTG",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Technik RTG",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Technik laboratorium",
                    secondName: "Diagnosta laboratoryjny",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Technicy, Diagności laboratoryjni",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Technik (przychodnia)",
                    secondName: "Pielęgniarka (przychodnia)",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Technicy i pielęgniarki",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Koordynator techników",
                    secondName: "Fizjoterapeuta",
                    thirdName: "Masażysta",
                    fourthName: "Logopeda",
                    fiveName: "",
                    finishName:
                        "Koordynator techników, fizjoterapeuta, masażysta, logopeda",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Koordynator (nadzór tech.)",
                    secondName: "Technik (nadzór tech.)",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Koordynatorzy, technicy",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Dietetyk",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Dietetycy",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Specjalista ds. epidemiologii",
                    secondName: "Pedagog",
                    thirdName: "Bhp",
                    fourthName: "Inspektor ochrony danych",
                    fiveName: "Radca prawny",
                    finishName:
                        "Specjalista ds. epidemiologii, pedagog, bhp, inspektor ochrony danych, radca prawny",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Informatyk",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Informatycy",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Koordynator (sekcja admin.)",
                    secondName: "Pracownik gospodarczy",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Koordynator, pracownik gospodarczy",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Pracownik",
                    secondName: "",
                    thirdName: "",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Pracownicy",
                    color: "#c6efce",
                    roleId: [],
                    arr: [[], [], []],
                },
                {
                    name: "Archiwista",
                    secondName: "Statystyk",
                    thirdName: "Pracownik (sekcja statystyki)",
                    fourthName: "",
                    fiveName: "",
                    finishName: "Archiwista, statystyk, pracownicy",
                    color: "#ffeb9c",
                    roleId: [],
                    arr: [[], [], []],
                },
            ];

            let allLogs = this.getThreeMonthLogs;
            let allDepartments = this.getDepartmentsItems;
            let allRoleArr = [];
            let roleArr = [];
            let finishArr = [];
            // wszytkie logi z podziałem na 3 ostatnie miesące
            roles.forEach((role) => {
                allLogs.forEach((element, index) => {
                    let month = index;
                    allLogs[index].forEach((element, index) => {
                        // sprawdzenie czy istnieje pracownik i czy ma przypisaną rolę
                        if (element.worker && element.worker.roleName) {
                            if (
                                element.worker.roleName == role.name ||
                                element.worker.roleName == role.secondName ||
                                element.worker.roleName == role.thirdName ||
                                element.worker.roleName == role.fourthName ||
                                element.worker.roleName == role.fiveName
                            ) {
                                // utworzenie tablicy z logami (podział na role i logi)
                                role.arr[month].push(element);
                                if (!role.roleId.includes(element.userId)) {
                                    role.roleId.push(element.userId);
                                }
                            }
                        }
                    });
                });
                allRoleArr.push(role);
            });

            // pobranie wszystkich departamentów
            allDepartments.forEach((dep, index) => {
                roleArr.push({
                    department: dep.fullName,
                    roleName: [],
                    sorted: [],
                });
                // sprawdzenie czy dany pracownik z 'tablicy z logami' jest w departamencie i przypisanie go do departamentu
                allRoleArr.forEach((role) => {
                    role.roleId.forEach((element) => {
                        if (dep.users.find((x) => x._id == element)) {
                            roleArr[index].roleName.push({
                                id: element,
                                color: role.color,
                                name: role.name,
                                finishName: role.finishName,
                                logs: [[], [], []],
                            });
                        }
                    });
                });
            });

            // przypisanie logów do pracownika

            roleArr.forEach((finish, index) => {
                if (finish.roleName.length > 0) {
                    finish.roleName.forEach((roles) => {
                        let findIndex = finish.sorted
                            .map((e) => e.name)
                            .indexOf(roles.name);
                        //utworzenie nowej tablicy i posorotowanie wg ról
                        if (findIndex == -1) {
                            finish.sorted.push({
                                name: roles.name,
                                finishName: roles.finishName,
                                color: roles.color,
                                logs: [[], [], []],
                            });
                            findIndex = finish.sorted
                                .map((e) => e.name)
                                .indexOf(roles.name);
                        }
                        allLogs.forEach((element, index) => {
                            allLogs[index].forEach((logs) => {
                                if (logs.userId == roles.id) {
                                    roles.logs[index].push(logs);
                                    finish.sorted[findIndex].logs[index].push(
                                        logs
                                    );
                                }
                            });
                        });
                    });
                }
            });

            roles.forEach((rolesName) => {
                roleArr.forEach((element) => {
                    element.sorted.forEach((roles) => {
                        if (roles.name == rolesName.name) {
                            finishArr.push({
                                name: roles.name,
                                department: element.department,
                                finishName: rolesName.finishName,
                                color: rolesName.color,
                                logs: roles.logs,
                            });
                        }
                    });
                });
            });
            this.finishArr = finishArr;
        },

        sendToEmail() {
            let node = document.getElementById("firsPage");
            const scale = 2;
            let obj = {
                height: 1200 * scale,
                style: {
                    transform: `scale(${scale}) translate(${
                        1900 / 2 / scale
                    }px, ${1100 / 2 / scale}px)`,
                },
                width: 1100 * scale,
            };

            let client = this.clientEmail;
            let index = this.getServiceDetails.index;

            if (client === "") {
                this.errorMessages = "Pole wymagane";
            } else {
                setTimeout(function () {
                    document.getElementById(
                        "order"
                    ).innerHTML = `<div class="printContent"><div class="printQRCode" style="font-size: 18px; text-align: center; line-height: 2; width: 250px">${"Trwa wysyłanie maila proszę czekać..."}</div><div class="printQRCode" style="font-size: 11px"></div></div><div id="preloader"><div id="loader">
                                        </div></div>`;
                }, 100);
                document.body.style.overflow = "hidden !important";
                this.errorMessages = "";
                let that = this;
                this.menu = false;

                const chars =
                    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

                let code = "";
                for (let i = 0; i < 6; i++) {
                    let index = Math.floor(Math.random() * chars.length);
                    code += chars[index];
                }
                domtoimage.toPng(node, obj).then(function (dataUrl) {
                    let img = new Image();
                    img.src = dataUrl;
                    let doc = new jsPDF();
                    doc.internal.scaleFactor = 6.1;
                    doc.addImage(img, "png", -60, 15);
                    var fd = new FormData();
                    fd.append("case", "pdf");
                    fd.append("mail", `${client}`);
                    fd.append("index", `${index}`);
                    fd.append("code", `${code}`);
                    fd.append("upl", doc.output("blob"));

                    Vue.axios({
                        method: "POST",
                        url: `/sendOrder`,
                        data: fd,
                    })
                        .then(() => {
                            that.setSnackbars({
                                type: "success",
                                text: "Załącznik został wysłany",
                            });
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        })
                        .catch(() => {
                            that.setSnackbars({
                                type: "error",
                                text: "Ups! Błąd wysyłania",
                            });

                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        });
                });
            }
        },
        print() {
            window.print();
            // this.$router.push("/service");
        },
        // checkHeight() {
        //   let ElementOffsetHeight = document.querySelector(".printContentWSD")
        //     .offsetHeight;
        //   let pagesHeight = document.querySelector(".printContacts").offsetHeight;

        //   let pages = ElementOffsetHeight / pagesHeight;
        //   this.pages = Math.ceil(pages) - 1;
        // },
    },

    mounted() {
        this.logs();
    },
};
</script>

<style lang="sass" scoped>
h1
  font-size: 10rem
  color: red
</style>
